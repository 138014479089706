"use client";
import { Button, Grid, HStack, Text } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

import Sidebar from "@/components/Sidebar";
import { Alert } from "@/components/ui/alert";
import { useAppContext } from "@/context/AppContext";
import { useErrorContext } from "@/context/ErrorContext";
import { useLayoutContext } from "@/context/LayoutContext";
import { CEvents, eventLabels } from "@/lib/state-machine";

const Main = ({ children }: PropsWithChildren) => {
  const { onStateChange, nextEvents } = useAppContext();
  const { isSidebarCollapsed } = useLayoutContext();
  const { error } = useErrorContext();

  return (
    <Grid
      templateColumns={isSidebarCollapsed ? "64px 1fr" : "288px 1fr"}
      h={"100%"}
    >
      <Sidebar />
      <Grid
        templateRows={
          error != null ? "minmax(64px, 400px) 1fr 200px" : "1fr 200px"
        }
        p={4}
        maxH={"calc(100vh - 64px)"}
        overflowY={"auto"}
      >
        {error != null && <Alert status={"error"}>{error.toString()}</Alert>}
        {children}
        <HStack gap={2} justifyContent={"flex-end"}>
          <Text fontSize={"lg"} color={"gray.400"}>
            DEBUG ACTIONS:
          </Text>
          {nextEvents.map((event: CEvents) => (
            <Button
              key={event}
              onClick={() => onStateChange(event)}
              variant={"outline"}
              color={"gray.400"}
            >
              {eventLabels[event]}
            </Button>
          ))}
        </HStack>
      </Grid>
    </Grid>
  );
};

export default Main;
