import {
  AccordionItem,
  AccordionItemContent,
  AccordionItemTrigger,
  AccordionRoot,
  Box,
  Flex,
  Group,
  IconButton,
  Input,
  InputAddon,
  Text,
  VStack,
} from "@chakra-ui/react";
import Image from "next/image";
import {
  LuChevronDown,
  LuChevronLeft,
  LuChevronRight,
  LuFolder,
  LuLogOut,
  LuMap,
  LuMessageSquare,
  LuSearch,
  LuSettings,
  LuSheet,
  LuUser,
} from "react-icons/lu";

import { Avatar } from "@/components/ui/avatar";
import {
  MenuContent,
  MenuItem,
  MenuRoot,
  MenuTrigger,
} from "@/components/ui/menu";
import { useLayoutContext } from "@/context/LayoutContext";
import { CStates } from "@/lib/state-machine";

import SidebarItem from "./SidebarItem";

const Sidebar = () => {
  const { isSidebarCollapsed, toggleSidebar } = useLayoutContext();

  return (
    <VStack
      w={isSidebarCollapsed ? "64px" : "280px"}
      h={"calc(100vh - 64px)"}
      transition="width 0.2s"
      bg="white"
      borderRight="1px"
      borderColor="gray.200"
      borderStyle="solid"
      align="stretch"
      py={4}
    >
      {/* Logo */}
      <Flex
        px={4}
        mb={4}
        align="center"
        justify={isSidebarCollapsed ? "center" : "space-between"}
      >
        <Flex align="center">
          <Image width={24} height={24} src="/logo.png" alt="bumblebee logo" />
          {!isSidebarCollapsed && (
            <Text fontSize="xl" fontWeight="semibold" ml={2}>
              Bumblebee
            </Text>
          )}
        </Flex>
        <IconButton
          aria-label="Toggle sidebar"
          size="sm"
          variant="ghost"
          onClick={() => toggleSidebar()}
        >
          {isSidebarCollapsed ? <LuChevronRight /> : <LuChevronLeft />}
        </IconButton>
      </Flex>

      {!isSidebarCollapsed && (
        <Box px={4} mb={6}>
          <Group attached>
            <InputAddon>
              <LuSearch />
            </InputAddon>
            <Input placeholder="Search" />
          </Group>
        </Box>
      )}

      {/* Navigation Items */}
      <VStack gap={1} align="stretch" mb={6}>
        <AccordionRoot
          defaultValue={["main"]}
          value={isSidebarCollapsed ? [] : ["main"]}
        >
          <AccordionItem value="main" borderBottom={0}>
            <AccordionItemTrigger value="main" p={0}>
              <SidebarItem
                cstate={CStates.HOME}
                icon={<LuFolder />}
                isCollapsed={isSidebarCollapsed}
              >
                My Analysis
              </SidebarItem>
            </AccordionItemTrigger>
            <AccordionItemContent>
              {["Analysis A", "Analysis B", "Analysis C"].map((item) => (
                <SidebarItem
                  key={item}
                  isNested
                  icon={<LuSheet />}
                  cstate={CStates.HOME}
                  isCollapsed={isSidebarCollapsed}
                >
                  {item}
                </SidebarItem>
              ))}
            </AccordionItemContent>
          </AccordionItem>
        </AccordionRoot>

        <SidebarItem
          href="/chat"
          icon={<LuMessageSquare />}
          isCollapsed={isSidebarCollapsed}
        >
          Ask a question
        </SidebarItem>
        <SidebarItem
          cstate={CStates.OBJECT_EXPLORER}
          icon={<LuMap />}
          isCollapsed={isSidebarCollapsed}
        >
          Explore entities
        </SidebarItem>
      </VStack>

      <Flex flexGrow={1} />

      {/* User Profile - Bottom */}
      <Box p={4} borderTop="1px" borderColor="gray.200">
        <MenuRoot>
          <MenuTrigger>
            <Flex
              align="center"
              justify={isSidebarCollapsed ? "center" : "flex-start"}
            >
              <Avatar
                size="sm"
                mr={isSidebarCollapsed ? 0 : 3}
                name="Charlie Brown"
              />
              {!isSidebarCollapsed && (
                <>
                  <Box flex={1} textAlign="left">
                    <Text fontWeight="medium">Charlie Brown</Text>
                    <Text fontSize="sm" color="gray.500">
                      cbrown@bumblebee.dev
                    </Text>
                  </Box>
                  <LuChevronDown />
                </>
              )}
            </Flex>
          </MenuTrigger>
          <MenuContent>
            <MenuItem value="new-txt-a">
              <LuLogOut /> Log out
            </MenuItem>
            <MenuItem value="new-file-a">
              <LuUser /> Profile
            </MenuItem>
            <MenuItem value="new-win-a">
              <LuSettings /> Settings
            </MenuItem>
          </MenuContent>
        </MenuRoot>
      </Box>
    </VStack>
  );
};

export default Sidebar;
