"use client";

import { createContext, PropsWithChildren, useContext, useState } from "react";

// Define the shape of your context
interface ContextType {
  isSidebarCollapsed: boolean;
  toggleSidebar: () => void;
}

// Create the context initial value
const LayoutContext = createContext<ContextType | undefined>(undefined);

// Create a provider component
export const LayoutContextProvider = ({ children }: PropsWithChildren) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);
  const toggleSidebar = () => setIsSidebarCollapsed(!isSidebarCollapsed);

  return (
    <LayoutContext.Provider value={{ isSidebarCollapsed, toggleSidebar }}>
      {children}
    </LayoutContext.Provider>
  );
};

// Create a custom hook to use the context
export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useLayoutContext must be used within a LayoutContext");
  }
  return context;
};
