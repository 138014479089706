import { Flex, HStack, IconButton } from "@chakra-ui/react";
import { Link as ChakraLink } from "@chakra-ui/react";
import NextLink from "next/link";
import { ReactNode } from "react";

import { useAppContext } from "@/context/AppContext";
import { CStates } from "@/lib/state-machine";

const SidebarItem = ({
  children,
  isNested,
  icon,
  href,
  cstate,
  isCollapsed,
}: {
  children: string;
  isNested?: boolean;
  icon: ReactNode;
  href?: string;
  cstate?: CStates;
  isCollapsed?: boolean;
}) => {
  const { navigateToCState } = useAppContext();
  return (
    <Flex
      px={isCollapsed ? 2 : 4}
      w={"100%"}
      align="center"
      cursor="pointer"
      _hover={{ bg: "gray.100" }}
      pl={isNested ? (isCollapsed ? 2 : 8) : isCollapsed ? 2 : 4}
      py={isNested ? 1 : 2}
      justify={isCollapsed ? "center" : "flex-start"}
      title={isCollapsed ? String(children) : undefined}
    >
      {isCollapsed ? (
        <IconButton variant={"ghost"} size={"xs"} aria-label={children}>
          {icon}
        </IconButton>
      ) : href != null ? (
        <ChakraLink
          w={"100%"}
          fontWeight={isNested ? 500 : 600}
          ml={2}
          color={isNested ? "gray.600" : "gray.700"}
          asChild
        >
          <NextLink href={href}>
            {icon} {children}
          </NextLink>
        </ChakraLink>
      ) : cstate != null ? (
        <ChakraLink
          w={"100%"}
          fontWeight={isNested ? 500 : 600}
          ml={2}
          color={isNested ? "gray.600" : "gray.700"}
          onClick={() => {
            navigateToCState(cstate);
          }}
        >
          <HStack gap={2}>
            {icon} {children}
          </HStack>
        </ChakraLink>
      ) : null}
    </Flex>
  );
};
export default SidebarItem;
